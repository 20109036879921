import React from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";

import { StyledOrangeButton } from "./styled";

export function OrangeButton(props) {
  const navigate = useNavigate();

  const handleOnClick = (url) => {
    ReactGA.event({
      category: `tlačítko - ${props.url}`,
      action: `${props.url}`,
    });

    navigate(url);
  };

  return (
    <StyledOrangeButton variant="outlined" onClick={() => handleOnClick(props.url)}>
      {props.text}
    </StyledOrangeButton>
  );
}
