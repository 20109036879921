import keysImg from "../images/Home/OurServices/keys.svg";
import tasksImg from "../images/Home/OurServices/tasks.svg";
import trainingImg from "../images/Home/OurServices/training.svg";
import forkliftImg from "../images/Home/OurServices/forklift.svg";
import batteryImg from "../images/Home/OurServices/battery.svg";
import repairingImg from "../images/Home/OurServices/repairing.svg";
import sailingImg from "../images/Home/OurServices/sailing.svg";
import repasImg from "../images/Home/OurServices/repas.svg";
import lpgImg from "../images/Home/OurServices/lpg.svg";
import buyingImg from "../images/Home/OurServices/buying.svg";

export const CardData = [
  {
    img: keysImg,
    alt: "Fotografie dvou zkřížených klíčů",
    header: "Servis vozíků",
    text: "Naší filizofií je vaše spokojenost díky pravidelným servisním programům, kterými omezíme provozní rizika a Vy se tak můžete plně koncentrovat na své podnikání. ",
    buttonText: "Kontaktovat",
    url: "",
    id: 0,
  },
  {
    img: tasksImg,
    alt: "Fotografie znázorňující splnění úkolu",
    header: "Technické kontroly",
    text: "Provádíme technické kontroly a revize LPG přímo u zákazníků dle požadavků technické normy ČSN 26 8805.",
    buttonText: "Kontaktovat",
    url: "",
    id: 1,
  },
  {
    img: trainingImg,
    alt: "Fotografie člověka stojícího u vysokozdvižného vozíku",
    header: "Školení řidičů",
    text: "Realizujeme přímo u Vás, nové i opakované školení s vystavením EURO či CZ průkazem.",
    buttonText: "Kontaktovat",
    url: "",
    id: 2,
  },
  {
    img: forkliftImg,
    alt: "Fotografie vysokozdvižného vozíku",
    header: "Prodej a pronájem vozíků",
    text: "Nabízíme prodej repasovaných strojů a krátkodobý či dlouhodobý pronájem z naší flotily.",
    buttonText: "Kontaktovat",
    url: "",
    id: 3,
  },
  {
    img: batteryImg,
    alt: "Fotografie trakční baterie",
    header: "Trakční baterie",
    text: "Prodej nových a repasovaných baterií. Diagnostika závad a navržení opravy nebo výměny.",
    buttonText: "Kontaktovat",
    url: "",
    id: 4,
  },
  {
    img: repairingImg,
    alt: "Fotografie znázorňující náhradní díly",
    header: "Náhradní díly",
    text: "Prodej náhradních dílů v originální kvalitě nebo levnější náhrady. Repasování komponentů - řídící jednotky, motory a hydraulické pístnice.",
    buttonText: "Kontaktovat",
    url: "",
    id: 5,
  },
  {
    img: sailingImg,
    alt: "Fotografie znázorňující rychlost a preciznost",
    header: "Prodej a servis nabíječů",
    text: "Odborná konzultace a doporučení vhodného nabíječe ke spokojenosti vaší trakční baterie. Diagnostika a servis včetně zapůjčení náhradního po dobu opravy.",
    buttonText: "Kontaktovat",
    url: "",
    id: 6,
  },
  {
    img: repasImg,
    alt: "Fotografie znázorňující repasování dílů",
    header: "Repasování komponentů",
    text: "Už nechcete platit předražené nové komponenty a hledáte levnější alternativu? Naše odborné dílny jsou k Vašim službám. Repasujeme hlavně řídící jednotky, motory, hydraulické pístnice, převodovky a další.",
    buttonText: "Kontaktovat",
    url: "",
    id: 7,
  },
  {
    img: lpgImg,
    alt: "Fotografie znázorňující revizi LPG",
    header: "Revize LPG",
    text: "Provedení odborné revize LPG našim technikem přímo u Vás. Kontrola systému LPG na prvním místě. Výstupem je protokol s platností jednoho roku.",
    buttonText: "Kontaktovat",
    url: "",
    id: 8,
  },
  {
    img: buyingImg,
    alt: "Fotografie znázorňující výkup vozíků",
    header: "Výkup vozíků",
    text: "Potřebujete vyměnit vozík nebo je pro Vás stávající již nepotřebný? Kontaktujte nás a my Váš stroj rádi odkoupíme nebo s Vámi odborně vyřešíme její výměnu.",
    buttonText: "Kontaktovat",
    url: "",
    id: 9,
  },
];
