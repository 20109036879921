import React, { useRef } from "react";
import { Container, Header, ArrowDown } from "./styled";

import ArrowDownImg from "../../images/Home/LandingPage/arrowDown.svg";
const TopBanner = (props) => {
  const scrollEdge = useRef(null);
  const executeScroll = () => scrollEdge.current.scrollIntoView();

  return (
    <>
      <Container
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${props.img}) no-repeat`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Header>{props.header}</Header>
        <ArrowDown onClick={executeScroll} src={ArrowDownImg} alt="Tlačítko sloužící pro scrollování na další obsah stránky"></ArrowDown>
      </Container>
      <div ref={scrollEdge}></div>
    </>
  );
};

export default TopBanner;
