import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home/index";
import CareerOverview from "./routes/Career/index";
import Career from "./routes/Career/career";
import Contact from "./routes/Contact";
import OurServices from "./routes/OurServices";
import ServiceOrder from "./routes/ServiceOrder";
import Machines from "./routes/Machines";
import MachineDetail from "./routes/Machines/machineDetail";
import Login from "./routes/Login";
import Administration from "./routes/Admin";
import AdminMachines from "./routes/AdminMachines";
import NewMachine from "./routes/AdminMachines/NewMachine";
import AdminDeletedMachines from "./routes/AdminMachines/DeletedMachines";
import NotFoundPage from "./routes/404";

const Router = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/" element={<Home />} />
      <Route path="/our-services" element={<OurServices />} />
      <Route path="/service-order" element={<ServiceOrder />} />
      <Route path="/career" element={<CareerOverview />} />
      <Route path="/career-position" element={<Career />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/machines" element={<Machines />} />
      <Route path="/machine-detail" element={<MachineDetail />} />
      <Route path="/login" element={<Login />} />
      <Route path="/administration" element={<Administration />} />
      <Route path="/administration/machines" element={<AdminMachines />} />
      <Route path="/administration/machines/deleted-machines" element={<AdminDeletedMachines />} />
      <Route path="/administration/machines/new-machine" element={<NewMachine />} />
    </Routes>
  );
};

export default Router;
