import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import NavBar from "./components/NavBar";
import Router from "./router";
import ScrollToTop from "./utils/ScrollToTop";
import CookiesBanner from "./components/CookiesBanner";
import { SWRConfig } from "swr";
import { Fetcher } from "./network";
import { GetSession } from "./utils/CheckSession";

const root = ReactDOM.createRoot(document.getElementById("root"));
//  <React.StrictMode>
root.render(
  <SWRConfig value={{ fetcher: (url) => Fetcher(url, GetSession()) }}>
    <BrowserRouter>
      <RecoilRoot>
        <ScrollToTop />
        <Router />
        <NavBar />
        <CookiesBanner />
      </RecoilRoot>
    </BrowserRouter>
  </SWRConfig>
);
