import React from "react";

import { Container, ContentContainer, Header, CardContainer, FormContainer, FormHeader } from "./styled";
import SpecialFooter from "./footer";

import Form from "./form";
import GoogleAnalytics from "../../utils/GoogleAnalytics";

const Contact = () => {
  GoogleAnalytics();

  return (
    <>
      <Container>
        <ContentContainer>
          <Header>Kontaktujte nás</Header>
          <CardContainer>
            <FormContainer>
              <FormHeader>Kontaktní formulář</FormHeader>
              <Form />
            </FormContainer>
          </CardContainer>
        </ContentContainer>
      </Container>
      <SpecialFooter />
    </>
  );
};

export default Contact;
