import styled from "styled-components";
import { Button } from "@mui/material";

export const Container = styled.div`
  position: relative;
  width: 95%;
  display: flex;
  margin: 100px auto;
  flex-direction: column;
`;

export const MainDataContainer = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const SliderContainer = styled.div`
  width: 50%;
  height: 700px;
  margin-right: 2rem;

  @media (max-width: 1100px) {
    height: 500px;
    width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 750px) {
    height: 400px;
  }

  @media (max-width: 600px) {
    width: 90%;
  }

  @media (max-width: 450px) {
    height: 270px;
  }
`;

export const DataContainer = styled.div`
  width: 40%;
  padding: 0 30px;

  @media (max-width: 1100px) {
    padding: 0;
    padding-left: 10%;
    margin-top: 20px;
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 90%;
    padding-left: 0;
    margin: 20px auto;
  }
`;

export const HeaderContainer = styled.div`
  position: relative;
`;

export const Header = styled.h3`
  font-size: 40px;
  color: #262626;

  @media (max-width: 1100px) {
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    font-size: 28px;
  }

  @media (max-width: 400px) {
    font-size: 24px;
  }
`;

export const HeaderStyleLine = styled.span`
  width: 30px;
  height: 2.5px;
  background-color: #262626;
  position: absolute;
  bottom: 0;
  left: 0;

  @media (max-width: 600px) {
    width: 20px;
    height: 1.5px;
  }
`;

export const PropertiesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

export const PropertyContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const PropertyImg = styled.img`
  height: 40px;
  margin-right: 10px;

  @media (max-width: 600px) {
    height: 30px;
  }

  @media (max-width: 400px) {
    height: 20px;
  }
`;

export const PropertyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PropertyHeader = styled.h4`
  font-size: 15px;
  color: #757575;
  font-weight: 500;

  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (max-width: 400px) {
    font-size: 8px;
  }
`;

export const PropertyText = styled.p`
  font-size: 20px;
  color: #262626;

  @media (max-width: 600px) {
    font-size: 16px;
  }

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

export const Text = styled.p`
  margin-top: 20px;
  font-size: 16px;
  color: #262626;

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

export const PriceContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
`;

export const PriceTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PriceText = styled.p`
  font-size: 26px;
  font-weight: 500;

  @media (max-width: 600px) {
    font-size: 24px;
  }
  @media (max-width: 400px) {
    font-size: 18px;
  }
`;

export const StyledHr = styled.hr`
  border: none;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
`;

export const StyledButton = styled(Button)`
  @media (max-width: 400px) {
    font-size: 12px !important;
  }
`;

export const TablesContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 3rem;
  flex-direction: row;
  margin-top: 100px;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const SpecificationContainer = styled.div`
  padding: 40px;
  width: 600px;
  height: max-content;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  @media (max-width: 1100px) {
    flex-direction: column;
    width: 72%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 780px) {
  }
`;

export const SpecificationSmallerContainer = styled.div`
  padding: 40px;
  width: 280px;
  height: max-content;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  @media (max-width: 1100px) {
    flex-direction: column;
    width: 72%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const SecondaryHeader = styled.h4`
  font-size: 30px;
  margin-bottom: 30px;
  color: #262626;

  @media (max-width: 600px) {
    font-size: 22px;
  }

  @media (max-width: 400px) {
    font-size: 20px;
  }
`;

export const SpecificationRow = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  @media (max-width: 400px) {
    margin-top: 15px;
  }
`;

export const RowHr = styled.hr`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  margin-top: 5px;
`;

export const SpecificationHeader = styled.p`
  color: #262626;

  @media (max-width: 800px) {
    font-weight: bold;
    margin-bottom: 5px;
  }

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

export const SpecificationText = styled.p`
  color: #262626;
  font-weight: bold;

  @media (max-width: 800px) {
    font-weight: 400;
  }

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;
