import styled from "styled-components";
import colors from "../../../global/colors";
import fontSize from "../../../global/fontSize";

export const ReferencesContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-image: linear-gradient(#fff, ${colors.backgroundLinearGrey});
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
`;

export const HeaderContainer = styled.div`
  width: 100vw;
  padding-top: 8rem;
  text-align: center;
`;

export const CardsContainer = styled.div`
  position: relative;
  margin-top: 2rem;
  width: 80%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  position: relative;
  width: 300px;
  min-height: 400px;
  background-color: #f4f6f7;
  border-radius: 15px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 75px;
  text-align: center;

  @media (max-width: 750px) {
    width: 260px;
    min-height: 360px;
  }
`;

export const CardHeader = styled.h4`
  color: ${colors.black};
  font-size: ${fontSize.text24};
  padding: 40px 20px 0 20px;

  @media (max-width: 750px) {
    font-size: ${fontSize.text};
    margin-bottom: 0;
    padding: 20px 20px 0 20px;
  }
`;

export const CardText = styled.p`
  color: ${colors.black};
  font-size: 14px;
  padding: 0 30px;
  margin-bottom: 30px;
  position: absolute;
  top: 32%;

  @media (max-width: 750px) {
    position: relative;
    margin-top: 15px !important;
    padding: 0 15px;
  }
`;

export const CardBottomContainer = styled.div`
  min-height: 170px;
  height: 170px;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 15px 15px;
  background-color: ${colors.black};

  @media (max-width: 750px) {
    min-height: 150px;
    height: 150px;
  }
`;

export const CardBottomImgContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 750px) {
    height: 80%;
  }
`;

// export const CardBottomImgContainer = styled.div`
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   height: 50%;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-wrap: wrap;

//   @media (max-width: 750px) {
//     height: 80%;
//   }
// `;

export const CardBottomIconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;

  img {
    color: white;
    height: 70px;
  }
  p {
    padding: 0 60px;
    line-height: 1.3em;
    color: #dcdcdc;
    font-size: 14px;
  }
`;

export const CardBottomImg = styled.img`
  margin: 10px;
  height: 18px;
`;

export const SliderTextContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    color: #dcdcdc;
    z-index: 20;
    padding: 0 50px;
    font-size: 14px;

    @media (max-width: 750px) {
      padding: 0 35px;
    }
  }

  h5 {
    color: #ffffff;
    font-weight: bolder;
    margin-bottom: 10px;
    font-size: 17px;

    @media (max-width: 690px) {
      font-size: 15px;
    }

    @media (max-width: 750px) {
      padding: 0 10px;
    }
  }
`;
