import styled from "styled-components";

export const Container = styled.div`
  padding: 0 50px;
  margin-top: 10rem;

  @media (max-width: 1525px) {
    padding: 0 50px;
  }

  @media (max-width: 980px) {
    padding: 0 10px;
  }
`;

export const HeaderContainer = styled.div`
  position: relative;
  height: 50px;
  width: 100%;
  text-align: center;

  @media (max-width: 1410px) {
    width: unset;
  }
`;

export const Header = styled.h3`
  font-size: 40px;
  color: #262626;
  display: inline-block;

  @media (max-width: 700px) {
    font-size: 32px;
  }
`;

export const HeaderStyleLine = styled.span`
  width: 120px;
  height: 2.5px;
  background-color: #262626;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 700px) {
    bottom: 5px;
  }
`;

export const CardContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10rem;
  gap: 4rem;
  margin-top: 50px;

  @media (max-width: 1410px) {
    justify-content: center;
  }
`;

export const Card = styled.div`
  position: relative;
  width: 320px;
  height: 220px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 25px 30px;
  transition: 0.2s;

  @media (max-width: 700px) {
    height: 150px;
  }

  :hover {
    cursor: pointer;
    transition: 0.2s;
    opacity: 0.7;
    transform: scale(1.1);
  }

  @media (max-width: 750px) {
    width: 250px;
  }
`;

export const CardHeader = styled.h4`
  height: 30%;
  font-size: 30px;

  @media (max-width: 700px) {
    font-size: 20px;
    height: 15%;
  }
`;

export const CardTextContainer = styled.div`
  height: 40%;
  width: 100%;
  margin-top: 20px;
`;

export const CardText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; /* limit to 2 lines */

  @media (max-width: 700px) {
    font-size: 14px;
  }
`;

export const CardMapContainer = styled.div`
  width: 100%;
  height: 15%;
  margin-top: 10px;
  display: flex;
  align-items: flex-end;

  @media (max-width: 700px) {
    height: 30%;
    font-size: 14px;
  }
`;

export const CardMapImg = styled.img`
  margin-right: 15px;
`;

export const CardMapText = styled.p`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
