import { React } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import styled from "styled-components";

import colors from "../../global/colors";
import fontSize from "../../global/fontSize";

const StyledButton = styled(Button)`
  text-transform: none !important;
  background-color: ${colors.black} !important;
  color: white !important;
  font-size: ${fontSize.smallerText} !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4) !important;
  border-radius: 5px !important;
  padding: 4px 30px !important;
`;

export function BlackButton(props) {
  const navigate = useNavigate();
  return (
    <StyledButton
      variant="outlined"
      onClick={() => {
        if (props.data) {
          navigate(props.url, { state: { value: props.data } });
        } else {
          navigate(props.url);
        }
      }}
    >
      {props.text}
    </StyledButton>
  );
}
