import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;

  @media (max-width: 1450px) {
    flex-direction: column;
  }
`;

export const SectionsContainer = styled.div`
  position: relative;
  width: 50%;
  margin: 5rem 0 0 8rem;

  @media (max-width: 1450px) {
    width: 100%;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Section = styled.div`
  width: 90%;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 3rem;
  padding: 50px;

  @media (max-width: 1450px) {
    width: 50%;
  }

  @media (max-width: 800px) {
    width: 70%;
    padding: 30px;
  }
`;

export const SectionHeader = styled.h3`
  font-size: 36px;
  margin-bottom: 1.5rem;

  @media (max-width: 700px) {
    font-size: 20px;
  }
`;

export const SectionText = styled.p`
  font-size: 18px;
  color: #262626;
  margin-bottom: 5px;

  @media (max-width: 700px) {
    font-size: 14px;
  }
`;

export const CvContainer = styled.div`
  width: 50%;
  position: relative;
  margin-top: 5rem;

  @media (max-width: 1450px) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }

  @media (max-width: 700px) {
    p {
      font-size: 14px;
    }
  }
`;

export const FormHeader = styled.h3`
  margin-bottom: 30px;
  color: #262626;
  font-size: 32px;

  @media (max-width: 700px) {
    font-size: 20px;
    text-align: left;
  }
`;

export const FormContainer = styled.div`
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 100px 70px;
  border-radius: 30px;

  @media (max-width: 1450px) {
    width: 47%;
  }

  @media (max-width: 800px) {
    width: 70%;
    padding: 70px 40px;
  }
`;

export const ErrorMsg = styled.p`
  color: red;
  margin-top: 5px;
`;

export const SuccessMsg = styled.p`
  color: #399b39;
  margin-top: 15px;
`;
