import styled from "styled-components";

export const FooterContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 250px;
  background-color: #262626;
  color: white;
  overflow-x: hidden;
  z-index: 2;

  @media (max-width: 1250px) {
    height: 300px;
  }
`;

export const LogoRow = styled.div`
  width: 100%;
  height: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoImg = styled.img``;

export const LinksRow = styled.div`
  width: 30%;
  margin: 40px auto 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 1250px) {
    width: 50%;
    flex-direction: column;
    margin: 40px 0 0 0;
    display: none;
  }
`;

export const Link = styled.p`
  transition: 0.2s;
  cursor: pointer;

  :hover {
    transition: 0.2s;
    opacity: 75%;
  }

  @media (max-width: 1250px) {
    margin: 5px 0;
  }
`;

export const ContactRow = styled.div`
  position: relative;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto 0 auto;

  @media (max-width: 1250px) {
    width: 100%;
    margin: 2rem 0 0 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  @media (max-width: 610px) {
    justify-content: start;
    width: 99%;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1250px) {
    margin-top: 30px;
  }
`;

export const ContactImg = styled.img`
  margin-right: 10px;

  @media (max-width: 1250px) {
    margin-left: 30px;
  }

  @media (max-width: 610px) {
    width: 20px;
  }
`;

export const ContactLink = styled.a`
  margin-right: 50px;
  color: white;
  text-decoration: none;
  transition: 0.2s;

  :hover {
    opacity: 75%;
    transition: 0.2s;
  }

  @media (max-width: 1250px) {
    margin-right: 20px;
  }

  @media (max-width: 610px) {
    font-size: 14px;
  }
`;
