import React from "react";
import Card from "../../../components/Card";
import { OrangeButton } from "../../../components/OrangeButton";

import { Header, MainInformationContainer, OurServicesContainer, Triangle, Text, CardsContainer } from "./styled";

import { CardData } from "../../../data/cardData";

const OurServices = () => {
  return (
    <OurServicesContainer>
      <MainInformationContainer>
        <Triangle />
        <Header>Naše služby</Header>
        <Text>
          Chtěli by jste využít naše služby? Stačí kliknout na tlačítko "Kontaktovat", a vyplnit formulář, my Vás pak budeme obratem kontaktovat. Jestli jste nenašli službu kterou hledáte, klikněte na
          tlačítko níže "Zobrazit všechny služby", nebo případně přejděte na stránku Kontakt, a nezávazně nás kontaktujte, jaký typ služby by jste s námi chtěli vyřešit. Děkujeme, že máte zájem o naše
          služby.
        </Text>
        <OrangeButton text="Zobrazit všechny služby" url="our-services" />
      </MainInformationContainer>
      <CardsContainer>
        {CardData.map(
          (props, index) =>
            index < 6 && <Card key={props.id} src={props.img} alt={props.alt} header={props.header} text={props.text} data={props.header} buttonText={props.buttonText} url="/Contact"></Card>
        )}
      </CardsContainer>
    </OurServicesContainer>
  );
};

export default OurServices;
