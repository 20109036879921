import { useState, React } from "react";
import { useNavigate } from "react-router-dom";

import { Nav, LogoElem, LinksContainer, Link, HamburgerContainer, HamburgerMenu } from "./styled";
import { GhostButton } from "../../components/GhostButton";

import { Twirl as Hamburger } from "hamburger-react";
import { motion, AnimatePresence } from "framer-motion";

import Logo from "../../images/Nav/logo.svg";
import blackLogo from "../../images/Nav/blackLogo.svg";

import { useLocation } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [logoPath, setLogoPath] = useState();
  const [backgroundChange, setbackgroundChange] = useState();
  const [colorChange, setColorChange] = useState();

  if (location.pathname === "/") {
    if (logoPath == null || (logoPath === blackLogo && window.scrollY < window.innerHeight + window.innerHeight / 2)) {
      setLogoPath(Logo);
      setbackgroundChange(false);
      setColorChange(false);
    }

    const changeNavbarColor = () => {
      if (window.scrollY >= 1) {
        setbackgroundChange(true);
      } else {
        setbackgroundChange(false);
      }
      if (window.scrollY >= window.innerHeight + window.innerHeight / 2) {
        setColorChange(true);
        setLogoPath(blackLogo);
      } else {
        setColorChange(false);
        setLogoPath(Logo);
      }
    };

    window.addEventListener("scroll", changeNavbarColor);
  } else if (logoPath === Logo || logoPath == null) {
    setLogoPath(blackLogo);
    setbackgroundChange(true);
    setColorChange(true);
  }

  const [hamburgerIsOpen, setHamburgerOpen] = useState(false);

  return (
    <>
      <Nav className={colorChange ? "whiteNavBackground" : "" || backgroundChange ? "darkNavBackground" : ""}>
        <LogoElem onClick={() => navigate("/")} src={logoPath} alt="Obrázek loga RPK Technik"></LogoElem>
        <LinksContainer>
          <Link onClick={() => navigate("/")}>
            <GhostButton type={colorChange} text="O nás"></GhostButton>
          </Link>
          <Link onClick={() => navigate("/service-order")}>
            <GhostButton type={colorChange} text="Objednávka servisu"></GhostButton>
          </Link>
          <Link onClick={() => navigate("/machines")}>
            <GhostButton type={colorChange} text="Pronájem a prodej"></GhostButton>
          </Link>
          <Link onClick={() => navigate("/career")}>
            <GhostButton type={colorChange} text="Kariéra"></GhostButton>
          </Link>
          <Link onClick={() => navigate("/contact")}>
            <GhostButton type={colorChange} text="Kontakt"></GhostButton>
          </Link>
        </LinksContainer>
      </Nav>

      <HamburgerContainer>
        <Hamburger
          rounded
          toggled={hamburgerIsOpen}
          onToggle={(toggled) => {
            if (toggled) {
              setHamburgerOpen(true);
            } else {
              setHamburgerOpen(false);
            }
          }}
        />
      </HamburgerContainer>
      <AnimatePresence>
        {hamburgerIsOpen ? (
          <HamburgerMenu as={motion.div} key="box" initial={{ x: "100vh" }} transition={{ duration: 0.5 }} exit={{ x: "100vh", duration: 0.5 }} animate={{ x: "0" }}>
            <LinksContainer>
              <Link
                onClick={() => {
                  navigate("/");
                  setHamburgerOpen(false);
                }}
              >
                <GhostButton type={true} text="O nás"></GhostButton>
              </Link>
              <Link
                onClick={() => {
                  navigate("/service-order");
                  setHamburgerOpen(false);
                }}
              >
                <GhostButton type={true} text="Objednávka servisu"></GhostButton>
              </Link>
              <Link
                onClick={() => {
                  navigate("/career");
                  setHamburgerOpen(false);
                }}
              >
                <GhostButton type={true} text="Kariéra"></GhostButton>
              </Link>
              <Link
                onClick={() => {
                  navigate("/machines");
                  setHamburgerOpen(false);
                }}
              >
                <GhostButton type={true} text="Pronájem a prodej"></GhostButton>
              </Link>
              <Link
                onClick={() => {
                  navigate("/contact");
                  setHamburgerOpen(false);
                }}
              >
                <GhostButton type={true} text="Kontakt"></GhostButton>
              </Link>
            </LinksContainer>
          </HamburgerMenu>
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default NavBar;
