import styled from "styled-components";

export const Nav = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  padding: 0 10px;
  z-index: 100;
  display: flex;
  align-items: center;
  transition: height 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const LogoElem = styled.img`
  cursor: pointer;
`;

export const LinksContainer = styled.div`
  height: 100%;
  padding: 0 50px;
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 0;
    width: 100%;
    justify-content: space-evenly;
  }
`;

export const Link = styled.div`
  position: relative;
  padding: 0 20px;
  border-radius: 10px;
`;

export const HamburgerContainer = styled.div`
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 10;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

  @media (min-width: 800px) {
    display: none;
  }
`;

export const HamburgerMenu = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 5;
  display: flex;
`;
