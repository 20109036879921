import ReactGA from "react-ga4";
import Cookies from "universal-cookie";

function GoogleAnalytics(consent) {
  const cookies = new Cookies();

  if (consent || cookies.get("cookieConsent") === "true") {
    ReactGA.initialize([
      {
        trackingId: "G-BPB6P3J0R4",
      },
    ]);

    ReactGA.send({ hitType: "pageView", page: window.location.pathname + window.location.search });
  }
}

export default GoogleAnalytics;
