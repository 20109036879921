import React, { useState } from "react";

import { Button, MenuItem, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import axios from "axios";
import { useFormik } from "formik";
import ReactGA from "react-ga4";
import ReCAPTCHA from "react-google-recaptcha";
import { ThreeDots } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import { SuccessMsg } from "../Career/careerStyled";

const Form = () => {
  const location = useLocation();
  let serviceValue, messageValue, machineNumber;

  if (!location.state) {
    serviceValue = "Jiné";
  } else {
    serviceValue = location.state.value;
    messageValue = "";
    machineNumber = "";

    if (location.state.machine) {
      machineNumber = location.state.machineNumber;
      messageValue = "Měl bych zájem o prodej/pronájem VZV " + location.state.machine;
    } else {
      machineNumber = "";
    }
  }

  const [loader, setLoader] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const recaptchaRef = React.useRef();

  const onSubmitWithReCAPTCHA = async () => {
    return await recaptchaRef.current.executeAsync();
  };

  const handleOnClick = () => {
    ReactGA.event({
      category: `formulář - kontakt`,
      action: `formulář - kontakt`,
    });
  };

  const formik = useFormik({
    initialValues: {
      companyName: "",
      name: "",
      email: "",
      phone: "",
      message: messageValue,
      service: serviceValue,
      machineNumber: machineNumber,
    },
    onSubmit: (values) => {
      if (onSubmitWithReCAPTCHA) {
        formik.values.phone = "+" + formik.values.phone;
        setLoader(true);

        if (formik.values.service !== "Prodej a pronájem vozíků") {
          formik.values.machineNumber = "0";
        }

        if (typeof machineNumber === "number") {
          formik.values.machineNumber = machineNumber.toString();
        } else if (machineNumber === undefined) {
          formik.values.machineNumber = "0";
        }

        axios.post(`${process.env.REACT_APP_URL}/sendEmail/contact`, values).then((response) => {
          if (response.status === 200) {
            formik.values.companyName = "";
            formik.values.name = "";
            formik.values.email = "";
            formik.values.phone = "";
            formik.values.message = "";
            serviceValue = "Jiné";
            formik.values.service = serviceValue;
            setSuccessMsg(true);
            setLoader(false);
            handleOnClick();
          } else {
            alert("Omlouváme se, ale při odesílání nastala chyba. Prosím, zkuste to znovu později");
            setLoader(false);
          }
        });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: "100%", position: "relative" }}>
      <TextField onChange={formik.handleChange} value={formik.values.companyName} name="companyName" type={"text"} variant="outlined" label="Jméno firmy" required fullWidth></TextField>
      <TextField
        onChange={formik.handleChange}
        value={formik.values.name}
        name="name"
        style={{ marginTop: "15px" }}
        type={"text"}
        variant="outlined"
        label="Jméno a příjmení"
        required
        fullWidth
      ></TextField>
      <TextField value={formik.values.email} onChange={formik.handleChange} name="email" style={{ marginTop: "15px" }} type={"email"} variant="outlined" label="E-mail" required fullWidth></TextField>
      <PhoneInput
        value={formik.values.phone}
        onChange={(phone) => (formik.values.phone = phone)}
        country={"cz"}
        style={{ marginTop: "15px" }}
        component={TextField}
        inputProps={{
          required: true,
          name: "phone",
          autoFocus: true,
        }}
      ></PhoneInput>
      <Select name="service" value={formik.values.service} onChange={formik.handleChange} variant="outlined" fullWidth style={{ marginTop: "15px" }} required>
        <MenuItem value={"Servis vozíků"}>Servis vozíků</MenuItem>
        <MenuItem value={"Technické kontroly"}>Technické kontroly</MenuItem>
        <MenuItem value={"Technické kontroly Revize LPG"}>Technické kontroly Revize LPG</MenuItem>
        <MenuItem value={"Školení řidičů"}>Školení řidičů</MenuItem>
        <MenuItem value={"Prodej a pronájem vozíků"}>Prodej a pronájem vozíků</MenuItem>
        <MenuItem value={"Trakční baterie"}>Trakční baterie</MenuItem>
        <MenuItem value={"Náhradní díly"}>Náhradní díly</MenuItem>
        <MenuItem value={"Prodej a servis nabíječů"}>Prodej a servis nabíječů</MenuItem>
        <MenuItem value={"Repasování komponentů"}>Repasování komponentů</MenuItem>
        <MenuItem value={"Revize LPG"}>Revize LPG</MenuItem>
        <MenuItem value={"Výkup vozíků"}>Výkup vozíků</MenuItem>
        <MenuItem value={"Jiné"}>Jiné</MenuItem>
      </Select>
      <TextField
        value={formik.values.message}
        onChange={formik.handleChange}
        name="message"
        style={{ marginTop: "15px" }}
        type={"text"}
        multiline
        rows={6}
        variant="outlined"
        label="Zpráva"
        required
        fullWidth
      ></TextField>
      <div style={{ textAlign: "left" }}>
        <ReCAPTCHA hl="cs" ref={recaptchaRef} size="invisible" style={{ visibility: "hidden" }} sitekey={process.env.REACT_APP_RECAPTCHA_KEY} />
      </div>
      {successMsg ? (
        <div style={{ textAlign: "left" }}>
          <SuccessMsg>Formulář byl úspešně odeslán!</SuccessMsg>
        </div>
      ) : null}
      <Button type="submit" variant="contained" style={{ width: "160px", backgroundColor: "#262626", marginTop: "15px", left: "50%", transform: "translate(-50%)" }}>
        Odeslat
      </Button>
      <ThreeDots
        height="50"
        width="80"
        radius="9"
        color="#262626"
        ariaLabel="three-dots-loading"
        wrapperStyle={{ position: "absolute", left: "50%", transform: "translatex(-50%)" }}
        wrapperClassName=""
        visible={loader}
      />
    </form>
  );
};

export default Form;
