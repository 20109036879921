import React from "react";
import {
  AboutUsContainer,
  FirstSlideTextContainer,
  SecondSlideTextContainer,
  Header,
  InformationContainer,
  InformationColumn,
  InformationImg,
  InformationHeader,
  Information1stRow,
  Information2ndRow,
  SlideText,
  InformationParagraph,
  SliderContainer,
} from "./styled";
import SmartSlider from "react-smart-slider";

import sliderImg1 from "../../../images/Home/AboutUs/slider1.jpg";
import sliderImg2 from "../../../images/Home/AboutUs/slider2.jpg";
import forkliftImg from "../../../images/Home/AboutUs/forklift.png";
import offerImg from "../../../images/Home/AboutUs/offer.png";
import learningImg from "../../../images/Home/AboutUs/learning.png";
import { motion } from "framer-motion";

const AboutUsPage = () => {
  const slidesArray = [
    {
      url: sliderImg1,
      childrenElem: (
        <FirstSlideTextContainer>
          <Header>Kdo jsme?</Header>
          <SlideText>
            Naše firma nabízí opravy a údržby vysokozdvižných vozíků přímo u zákazníků. Servis je zajištěn profesionálně vyškolenými servisními techniky vybavenými pojízdnými dílnami.
          </SlideText>
        </FirstSlideTextContainer>
      ),
    },
    {
      url: sliderImg2,
      childrenElem: (
        <SecondSlideTextContainer style={{ height: "400px" }}>
          <Header>
            Jezdíme přímo k Vám, <br /> po celých Čechách
          </Header>
        </SecondSlideTextContainer>
      ),
    },
  ];

  const informations = [
    {
      img: learningImg,
      alt: "Obrázek naznačující vysokou zkušenost",
      header: "Více než 17ti letá praxe",
      text: "Servis vysokozdvižných vozíků už děláme spoustu let, a díky získané praxi řešíme problémy s Vašimi vozíky rychle, efektivně a s vysokou kvalitou.",
      id: 0,
    },
    {
      img: offerImg,
      alt: "Obrázek naznačující nabídku služeb",
      header: "Nabízíme opravy a údržby vysokozdvižných vozíků",
      text: "Řešíme opravy, servis, technické kontroly a mnohem víc! Vaše pohodlí je pro nás důležité, a proto nabízíme mnoho služeb, abyste již starosti s vysokozdvižnými vozíky řešili pouze s jednou firmou. ",
      id: 1,
    },
    {
      img: forkliftImg,
      alt: "Obrázek vysokozdvižného vozíku",
      header: "Pracujeme s mnoha značkami vysokozdvižných vozíků",
      text: "Už Vás unavuje volat pro každou značku vozíku jinou firmu? Díky nám již nemusíte! Stačí nás kontaktovat, a my se už o vše postaráme!",
      id: 2,
    },
  ];

  return (
    <AboutUsContainer>
      <SliderContainer style={{ height: `${window.innerHeight / 1.5}px` }}>
        <SmartSlider slides={slidesArray} autoSlide="true" autoSlideInterval={7000} height={window.innerHeight / 1.5} />
      </SliderContainer>
      <InformationContainer>
        {informations.map((column) => {
          return (
            <InformationColumn as={motion.div} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ once: false }} key={column.id}>
              <Information1stRow>
                <InformationImg src={column.img} alt={column.alt} />
                <InformationHeader>{column.header}</InformationHeader>
              </Information1stRow>
              <Information2ndRow>
                <InformationParagraph>{column.text}</InformationParagraph>
              </Information2ndRow>
            </InformationColumn>
          );
        })}
      </InformationContainer>
    </AboutUsContainer>
  );
};

export default AboutUsPage;
