import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import GoogleAnalytics from "../../utils/GoogleAnalytics";
import { AcceptButton, Container, DeclineButton, Header, Text } from "./styled";

function CookiesBanner() {
  const [consent, setConsent] = useState(true);

  const cookies = new Cookies();
  useEffect(() => {
    setConsent(cookies.get("cookieConsent"));
  }, []);

  const acceptCookie = () => {
    setConsent(true);
    GoogleAnalytics(true);
    cookies.set("cookieConsent", "true", { maxAge: 60 * 60 * 24 * 365 });
  };
  const denyCookie = () => {
    setConsent(true);
    cookies.set("cookieConsent", "false", { maxAge: 60 * 60 * 24 * 365 });
  };
  if (consent === true) {
    return null;
  }

  if (consent === "false" || consent === undefined) {
    return (
      <Container className={"blackCookieBar"}>
        <Header>Tato stránka používá soubory cookies</Header>
        <Text>
          Na těchto stránkách jsou použity soubory cookies. Cookies můžeme používat pro zjištění statistik návštěvnosti webu nebo k personalizaci
          obsahu a reklam. Pro uložení cookies, které nejsou nezbytné pro provoz stránky, potřebujeme dle legislativy EU Váš souhlas
        </Text>
        <div>
          <DeclineButton variant="contained" color="error" onClick={denyCookie}>
            Odmítnout
          </DeclineButton>
          <AcceptButton variant="contained" color="success" onClick={acceptCookie}>
            Příjmout
          </AcceptButton>
        </div>
      </Container>
    );
  }
}

export default CookiesBanner;
