export async function GetBusinessData(IC) {
  try {
    const res = await fetch(`${process.env.REACT_APP_URL}/ares/get-business-data`, {
      method: "POST",
      body: JSON.stringify({ ico: IC }),
      headers: { "Content-Type": "application/json" },
    });

    if (res.headers.get("Content-Length") === "0" || !res.ok) {
      return;
    }

    if (res.status === 200) {
      const data = await res.json();
      console.log(data);
    } else {
      console.log(res.status);
    }
  } catch (err) {
    console.error(err);
  }
}

export async function GetMatchingBusinesses(companyName, setMatchingBusinessesData) {
  try {
    const res = await fetch(`${process.env.REACT_APP_URL}/ares/get-matching-businesses`, {
      method: "POST",
      body: JSON.stringify({ companyName: companyName }),
      headers: { "Content-Type": "application/json" },
    });

    if (!res.ok) {
      return;
    }

    if (res.status === 200) {
      const data = await res.json();
      if (data.pocetCelkem > 0) setMatchingBusinessesData(data.ekonomickeSubjekty);
    } else {
      console.log(res.status);
    }
  } catch (err) {
    console.error(err);
  }
}
