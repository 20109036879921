import styled from "styled-components";

import colors from "../../global/colors";
import fontSize from "../../global/fontSize";

export const CardContainer = styled.div`
  position: relative;
  width: 300px;
  height: 45%;
  background-color: #f4f6f7;
  border-radius: 10px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
  margin: 0 25px 75px 25px;
  text-align: center;
  transition: 0.2s;

  :hover {
    cursor: pointer;
    transition: 0.2s;
    transform: scale(1.1);
  }

  @media (min-width: 900px) and (max-height: 1500px) {
    height: 340px;
    margin: 0 25px 90px 25px;
  }

  @media (max-width: 1750px) {
    width: 250px;
  }
  @media (max-width: 1500px) {
    width: 260px;
    height: 360px;
  }
`;

export const Card1stRow = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Card2ndRow = styled.div`
  width: 100%;
`;
export const Card3thRow = styled.div`
  width: 100%;
`;
export const Card4thRow = styled.div`
  width: 100%;
  height: 15%;
  position: absolute;
  bottom: 0;
`;

export const CardHeader = styled.h4`
  color: ${colors.black};
  font-size: ${fontSize.text};
  margin-bottom: 20px;
`;

export const CardText = styled.p`
  color: ${colors.black};
  font-size: 14px;
  padding: 0 25px;

  @media (max-width: 1500px) {
    padding: 0 10px;
  }
`;
