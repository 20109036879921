import { motion } from "framer-motion";
import { React } from "react";
import { useSearchParams } from "react-router-dom";
import TopBanner from "../../components/TopBanner";

import Footer from "../../components/Footer";
import { CareerData } from "../../data/careerData";

import bannerBackgroundImg from "../../images/Career/backgroundImg.jpg";
import GoogleAnalytics from "../../utils/GoogleAnalytics";
import { Container, CvContainer, FormContainer, FormHeader, Section, SectionHeader, SectionText, SectionsContainer } from "./careerStyled";
import Form from "./form";

const Career = () => {
  GoogleAnalytics();

  const [searchparams] = useSearchParams();
  const mainHeader = searchparams.get("header");
  const id = searchparams.get("id");

  return (
    <>
      <TopBanner img={bannerBackgroundImg} header={mainHeader} />
      <Container>
        <SectionsContainer>
          {CareerData[id].map((props) => {
            return (
              <Section as={motion.div} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ once: false }} key={props.sectionId}>
                <SectionHeader>{props.header}</SectionHeader>
                {props.text.map((paragraph) => {
                  return <SectionText>{paragraph}</SectionText>;
                })}
              </Section>
            );
          })}
        </SectionsContainer>
        <CvContainer as={motion.div} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ once: false }}>
          <FormContainer>
            <FormHeader>Pošlete nám životopis</FormHeader>
            <Form />
          </FormContainer>
        </CvContainer>
      </Container>
      <Footer />
    </>
  );
};

export default Career;
