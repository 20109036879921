import React from "react";
import { FooterContentContainer, ContactImg, ContactLink, ContactContainer, ContactsContainer, FooterContainer, LogoImg, LogoContainer, IcoElem, FooterBottomContainer } from "./styled";

import Logo from "../../images/Footer/logo.svg";
import PhoneImg from "../../images/Footer/phone.svg";
import MapImg from "../../images/Footer/map.svg";
import EmailImg from "../../images/Footer/email.svg";

const SpecialFooter = () => {
  return (
    <FooterContainer>
      <FooterContentContainer>
        <ContactsContainer>
          <ContactContainer>
            <ContactImg src={PhoneImg} alt="Obrázek znázorňující telefon" />
            <ContactLink href="tel:+420739235235">+420 739 235 235</ContactLink>
          </ContactContainer>
          <ContactContainer>
            <ContactImg src={MapImg} alt="Obrázek znázorňující lokaci" />
            <ContactLink
              target="_blank"
              href="https://www.google.cz/maps/place/RPK+TECHNIK/@49.8760413,14.0994164,17z/data=!3m1!4b1!4m5!3m4!1s0x470ba710bad4433b:0xdc0491a90fc84191!8m2!3d49.8760208!4d14.1017205"
            >
              RPK TECHNIK s.r.o Všeradice 96, 267 26
            </ContactLink>
          </ContactContainer>
          <ContactContainer>
            <ContactImg src={EmailImg} alt="Obrázek znázorňující poštovní obálku" />
            <ContactLink href="mailto: servis@rpktechnik.cz">servis@rpktechnik.cz</ContactLink>
          </ContactContainer>
        </ContactsContainer>
        <LogoContainer>
          <LogoImg src={Logo} alt="Logo firmy RPK Technik" />
          <IcoElem>IČO: 07676107 / DIČ: CZ07676107</IcoElem>
        </LogoContainer>
      </FooterContentContainer>
      <FooterBottomContainer>
        <p>Zapsán v obchodním rejstříku vedeném Městským soudem v Praze, oddíll C, vložka 305273</p>
      </FooterBottomContainer>
    </FooterContainer>
  );
};

export default SpecialFooter;
