import React from "react";
import Button from "@mui/material/Button";
import styled from "styled-components";

const StyledGhostButton = styled(Button)`
  text-transform: none !important;
  font-size: 16px !important;
`;

export function GhostButton(props) {
  let colorStyle = {
    color: "black",
  };
  if (!props.type) {
    colorStyle.color = "white";
  }
  return (
    <StyledGhostButton variant="text" style={colorStyle}>
      {props.text}
    </StyledGhostButton>
  );
}
