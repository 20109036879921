import styled from "styled-components";
import { TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";

export const Container = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(#e5e5e5, #fff);
`;

export const ContentContainer = styled.div`
  width: 60vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 auto 0 auto;
`;

export const FormContainer = styled.div`
  width: 700px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin: 100px auto 100px auto;
  padding: 80px;
  background-color: white;

  @media (max-width: 1100px) {
    width: 70%;
    padding: 60px 30px;
  }

  @media (max-width: 500px) {
    width: 80%;
    padding: 60px 20px;
  }
`;

export const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const LeftFormContainer = styled.div`
  width: 50%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const RightFormContainer = styled.div`
  width: 50%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const FormHeader = styled.h4`
  font-size: 16px;
`;

export const StyledTextField = styled(TextField)`
  margin-top: 20px !important;

  @media (max-width: 800px) {
    margin-top: 15px !important;

    input {
      height: 35px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    label {
      top: -4px;
    }
  }
`;

export const StyledPhoneInput = styled(PhoneInput)`
  margin-top: 20px !important;

  @media (max-width: 800px) {
    margin-top: 15px !important;

    input {
      height: 45px !important;
    }
  }
`;
