import React, { useRef } from "react";
import { LandingContainer, HeaderBackground, HeaderTextContainer, LandingHeader, ForkliftImg, ArrowDown } from "./styled";
import Forklift from "../../../images/Home/LandingPage/forklift.png";
import ArrowDownImg from "../../../images/Home/LandingPage/arrowDown.svg";
import { OrangeButton } from "../../../components/OrangeButton";

const LandingPage = () => {
  const scrollEdge = useRef(null);
  const executeScroll = () => scrollEdge.current.scrollIntoView();

  return (
    <LandingContainer>
      <HeaderBackground>
        <HeaderTextContainer>
          <LandingHeader>
            Servis, prodej a pronájem <br />
            vysokozdvižných vozíků
          </LandingHeader>
          <OrangeButton url="service-order" text="Objednávka servisu"></OrangeButton>
        </HeaderTextContainer>
      </HeaderBackground>
      <ForkliftImg src={Forklift} alt="Vysokozdvižný vozík značky Toyota"></ForkliftImg>
      <ArrowDown onClick={executeScroll} src={ArrowDownImg} alt="Tlačítko sloužící pro scrollování na další obsah stránky"></ArrowDown>
      <div ref={scrollEdge}></div>
    </LandingContainer>
  );
};

export default LandingPage;
