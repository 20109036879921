import React from "react";
import { useNavigate } from "react-router-dom";
import { BlackButton } from "../BlackButton";
import { Card1stRow, Card2ndRow, Card3thRow, Card4thRow, CardContainer, CardHeader, CardText } from "./styled";

const Card = (props) => {
  const navigate = useNavigate();

  // as={motion.div}
  // initial={{ opacity: 0 }}
  // whileInView={{ opacity: 1 }}
  // viewport={{ once: false }} ON CARD CONTAINER

  return (
    <CardContainer
      onClick={() => {
        if (props.data) {
          navigate(props.url, { state: { value: props.data } });
        } else {
          navigate(props.url);
        }
      }}
    >
      <Card1stRow>
        <img src={props.src} alt={props.alt} />
      </Card1stRow>
      <Card2ndRow>
        <CardHeader>{props.header}</CardHeader>
      </Card2ndRow>
      <Card3thRow>
        <CardText>{props.text}</CardText>
      </Card3thRow>
      <Card4thRow>
        <BlackButton url={props.url} data={props.data} text={props.buttonText} />
      </Card4thRow>
    </CardContainer>
  );
};

export default Card;
