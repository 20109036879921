import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import i404 from "../../images/404.svg";

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 400px;
  height: 600px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 0 50px;

  @media (max-width: 800px) {
    width: 300px;
    height: 500px;
    padding: 0 25px;
    gap: 1rem;
  }
`;

export const Image = styled.img`
  height: 200px;
  margin-bottom: 20px;

  @media (max-width: 800px) {
    height: 120px;
  }
`;

const Header = styled.h1`
  font-size: 32px;
  font-weight: bolder;
  text-align: center;
`;

const TextContainer = styled.div`
  text-align: center;
`;

const Text = styled.p``;

function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Container>
        <Image src={i404} alt="Smutný smajlík" />
        <Header>Stránka nebyla nalezena</Header>
        <TextContainer>
          <Text>Je nám líto, ale požadovaná stránka nebyla nalezena. Ujistěte se, že jste neudělali chybu v URL adrese. Je možné, že byla stránka přemístěna nebo odstraněna.</Text>
        </TextContainer>
        <Button variant="contained" fullWidth style={{ backgroundColor: "#262626", marginTop: "15px", transition: "0.2s" }} onClick={() => navigate("/")}>
          Zpět na hlavní stránku
        </Button>
      </Container>
    </Wrapper>
  );
}

// <Wrapper>
// <Container>
//   <Image src={i404} alt="Smutný smajlík" />
//   <Header>Stránka nebyla nalezena</Header>
//   <TextContainer>
//     <Text>Je nám líto, ale požadovaná stránka nebyla nalezena. Ujistěte se, že jste neudělali chybu v URL adrese. Je možné, že byla stránka přemístěna nebo odstraněna.</Text>
//   </TextContainer>
//   <Button variant="contained" fullWidth style={{ backgroundColor: "#262626", marginTop: "15px", transition: "0.2s" }} onClick={() => navigate("/")}>
//     Zpět na hlavní stránku
//   </Button>
// </Container>
// </Wrapper>

export default NotFoundPage;
