import styled from "styled-components";

import fontSize from "../../../global/fontSize";
import colors from "../../../global/colors";

export const AboutUsContainer = styled.div`
  position: relative;
  width: 100vw;
  background-image: linear-gradient(#fff, ${colors.backgroundLinearGrey});
  overflow-x: hidden;
`;

export const FirstSlideTextContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 40%;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  text-align: center;
`;

export const SecondSlideTextContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 60%;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  text-align: center;

  @media (max-width: 750px) {
    height: 60%;
  }
`;

export const Header = styled.h2`
  font-weight: 800;
  font-size: ${fontSize.secondMainHeader};
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.25);

  @media (max-width: 1250px) {
    font-size: ${fontSize.smallHeader};
  }

  @media (max-width: 750px) {
    font-size: 40px;
  }

  @media (max-width: 500px) {
    font-size: 32px;
  }
`;

export const SlideText = styled.p`
  padding: 0 50px;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const InformationContainer = styled.div`
  width: 100%;
  margin-top: 6rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const InformationColumn = styled.div`
  width: 500px;
  height: 250px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1500px) {
    width: 400px;
  }

  @media (max-width: 550px) {
    width: 300px;
  }
`;

export const Information1stRow = styled.div`
  height: 100px;
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  @media (max-width: 550px) {
    height: unset;
    margin-bottom: 20px;
  }
`;

export const InformationImg = styled.img`
  width: 45px;
  height: 45px;
  margin-right: 25px;

  @media (max-width: 550px) {
    width: 35px;
    height: 35px;
  }
`;

export const Information2ndRow = styled.div`
  padding-left: 75px;
  max-width: 70%;

  @media (max-width: 550px) {
    padding-left: 65px;
  }
`;

export const InformationHeader = styled.h4`
  color: ${colors.black};
  font-weight: 800;
  font-size: ${fontSize.text24};

  @media (max-width: 550px) {
    font-size: ${fontSize.text};
  }
`;

export const InformationParagraph = styled.p`
  font-weight: 400;

  @media (max-width: 550px) {
    font-size: 14px;
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
  position: relative;
`;
